<template>
  <section>
    <div class="column">
      <div class="columns">
        <div class="column is-narrow">
          <b-taglist attached>
            <b-tag class="is-dark">
              Gravidade:
            </b-tag>
            <b-tag class="is-danger">
              {{ row.gravidade }}
            </b-tag>
          </b-taglist>
        </div>
        <div class="column is-narrow">
          <b-taglist attached>
            <b-tag class="is-dark">
              Pontos:
            </b-tag>
            <b-tag class="is-danger">
              {{ row.pontos }}
            </b-tag>
          </b-taglist>
        </div>
      </div>
      <div>
        <p><strong>Status da Infração:</strong> {{ row.statusInfracao }}</p>
        <p><strong>Enquadramento:</strong> {{ row.enquadramentoCompleto }}</p>
        <p><strong>Recursos:</strong> {{ ultimoRecurso }}</p>
        <p>
          <strong>Indicação:</strong> <span v-show="listaIndicacoes().length === 0">Sem Indicação</span>
          <indicacao-detail v-for="(indicacao, index) in listaIndicacoes()" :key="index" :indicacao="indicacao" />
        </p>
        <p><strong class="error">{{ row.erroIndicacaoCondutor }}</strong></p>
        <p>
        </p>
        <p><strong class="error">{{ row.erroProtocolo }}</strong></p>
        <p>
          <b-button
            type="is-text"
            v-show="totalIndicacoesArquivadas > 0"
            @click="indicacoesArquivadasShow = !indicacoesArquivadasShow">
            Recusadas ({{ totalIndicacoesArquivadas }})
          </b-button>
          <indicacao-detail
            v-show="indicacoesArquivadasShow"
            v-for="(indicacao, index) in listaIndicacoesArquivadas()"
            :key="index"
            :indicacao="indicacao"
          />
        </p>
      </div>
    </div>
    <div class="column">
      <a :href="infracaoDetailsLink" class="button is-info is-light mr-1" target="_blank">Detalhes da Infração</a>
      <a v-show="mostrarSegundaVia" v-if="row.dataNotificacao !== null || row.dataBoleto !== null" :href="notificacaoLink" class="button is-info is-light mr-1" target="_blank">Segunda Via</a>
      <router-link v-show="permitirIndicacaoCondutor" :to="`/infracoes/indicacao/${row.idInfracao}`">
        <b-button type="is-info is-light mr-1" v-if="row.erroIndicacaoCondutor == '' && deveExibirBotaoIndicacaoCondutor">
          Indicação de Condutor
        </b-button>
      </router-link>
      <router-link :to="`/infracoes/recurso/${row.idInfracao}`">
        <b-button class="recursoBtn" v-if="row.aderiuSNE40 === null" type="is-info is-light mr-1 ">
          Solicitação de Recurso
        </b-button>
      </router-link>
      <button @click="mostrarVideoCard" target="_blank" v-if="row.idStorage !== null" class="button is-info is-light mr-1">Mostrar Vídeo</button>
        <div v-if="showModal" class="modal">
          <div class="modal-content">
            <div v-if="mostrarCard">
              <iframe width="560" height="315" :src="'https://player.vimeo.com/video/' + row.idStorage" frameborder="0" allowfullscreen></iframe>
            </div>
            <button @click="fecharModal" class="close-btn">&times;</button>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import IndicacaoDetail from './indicacao-detail.vue'
export default {
  name: 'RowDetail',
  components: {
    IndicacaoDetail
  },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      indicacoesArquivadasShow: false,
      mostrarCard: false,
      showModal: false
    }
  },
  computed: {
    ...mapState({
      hash: state => state.sessions.hash
    }),
    ...mapGetters({
      podeIndicarCondutor: 'infracoes/podeIndicarCondutor',
      indicacoesArquivadas: 'infracoes/indicacoesArquivadas',
      indicacoes: 'infracoes/indicacoes'
    }),
    infracaoDetailsLink () {
      return `${process.env.VUE_APP_BASE_URL}/documento/${process.env.VUE_APP_CLIENTID}/extratomulta?infracao=${this.row.idInfracao}&hash=${this.hash}`
    },
    notificacaoLink () {
      return `${process.env.VUE_APP_BASE_URL}/documento/${process.env.VUE_APP_CLIENTID}/segundaVia?infracao=${this.row.idInfracao}&hash=${this.hash}`
    },
    deveExibirBotaoIndicacaoCondutor () {
      return this.listaIndicacoes().every(indicacao => indicacao.status !== 'Em Análise')
    },
    ultimoRecurso () {
      if (this.row.recursos.length === 0) {
        return 'Sem Recursos'
      }
      const rec = this.row.recursos[this.row.recursos.length - 1]

      return `${rec.recurso} - ${rec.resultado}`
    },
    mostrarSegundaVia () {
      return this.row.isPending
    },
    permitirIndicacaoCondutor () {
      const ret = this.podeIndicarCondutor(this.row.idInfracao)
      return ret
    },
    totalIndicacoesArquivadas () {
      const ret = this.indicacoesArquivadas(this.row.idInfracao)
      return ret.length
    }
  },
  mounted () {
    if (this.row.indicacoes.length === 0) {
      this.getIndicacoes(this.row.idInfracao)
    }
  },
  methods: {
    ...mapActions('infracoes', [
      'getIndicacoes'
    ]),
    listaIndicacoes () {
      return this.indicacoes(this.row.idInfracao)
    },
    mostrarVideoCard () {
      this.showModal = true
      this.mostrarCard = true
    },
    fecharModal () {
      this.mostrarCard = false
      this.showModal = false
    },
    listaIndicacoesArquivadas () {
      return this.indicacoesArquivadas(this.row.idInfracao)
    }
  }
}
</script>
<style scoped>
/* Adicione estilos de modal conforme necessário */
/* Estilos do modal ajustados */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 60%; /* Defina o valor desejado para o máximo de largura */
  width: 100%; /* Isso garante que a largura seja ajustada conforme necessário */
  max-height: 80vh; /* Defina o valor desejado para a altura máxima (80% da altura da tela) */
  overflow-y: auto; /* Adiciona uma barra de rolagem vertical se necessário */
  text-align: center; /* Centraliza o conteúdo horizontalmente */
  margin: auto; /* Centraliza o modal na tela */
}

.close-btn {
  position: absolute;
  top: 1px;
  right: 10px;
  font-size: 40px;
  color: red;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}
</style>
