<template>
  <div class="container">
    <b-loading
      :is-full-page="true"
      :active.sync="loading"
      :can-cancel="false"
    />
    <div class="box" style="margin-top:20px; margin-bottom:50px">
      <div>
        <div class="is-size-3 column is-12">
          <b-icon icon="car" size="is-large" />
          <span>{{ placa }}</span>
        </div>
      </div>
      <div>
        <b-table
          ref="table"
          :data="infracoes"
          :paginated="paginated"
          :per-page="perPage"
          :opened-detailed="defaultOpenedDetails"
          :show-detail-icon="false"
          detailed
          detail-key="idInfracao"
          aria-next-label="Próximo"
          aria-previous-label="Anterior"
          aria-page-label="Pagina"
          aria-current-label="Pagina Atual"
        >
          <b-table-column v-slot="props" field="ait" label="AIT" sortable>
            {{ props.row.ait }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="dataHoraInfracao"
            label="Data/Hora da Infração"
            sortable
          >
            {{ formatDate(props.row.dataHoraInfracao) }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="localResumido"
            label="Local"
            sortable
          >
            {{ props.row.localResumido }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="valorInfracao"
            label="Valor"
            sortable
          >
            {{ formatValue(props.row.valorInfracao) }}
          </b-table-column>
          <b-table-column v-slot="props" field="status" label="Status" sortable>
            {{ props.row.status }}
          </b-table-column>
          <b-table-column v-slot="props">
            <b-tooltip
              label="Clique aqui para exibir informações sobre a infração"
              type="is-primary"
              animated
            >
              <b-button
                :icon-left="defaultOpenedDetails.includes(props.row.idInfracao) ? 'minus' : 'menu'"
                :label="defaultOpenedDetails.includes(props.row.idInfracao) ? 'Ocultar' : 'Detalhes'"
                type="is-text"
                @click="toggle(props.row)"
              />
            </b-tooltip>
          </b-table-column>

          <template slot="detail" slot-scope="props">
            <row-detail :row="props.row" />
          </template>
          <template slot="empty" v-if="!this.loading && this.infracoes.length == 0">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="emoticon-sad" size="is-large" />
                </p>
                <p>Não conseguimos encontrar infrações cadastradas para esta placa.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
      <div class="card-content">
        <b-button type="is-primary" @click="returnIndexPage()">
          Voltar
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { format } from 'date-fns'
import rowDetail from '../../components/infracao/row-detail'

export default {
  name: 'Infracoes',
  components: {
    rowDetail
  },
  layout: process.env.VUE_APP_LAYOUT_DEFAULT,
  data () {
    return {
      paginated: true,
      perPage: 20,
      defaultOpenedDetails: [],
      loading: true
    }
  },
  computed: {
    ...mapState({
      infracoes: state => state.infracoes.infracoes,
      placa: state => state.infracoes.placa
    }),
    valorTotal () {
      let ret = 0

      this.infracoes.forEach((infracao) => {
        ret += infracao.isPending ? infracao.valorInfracao : 0
      })

      return ret
    },
    mostrarBoletosEmAberto () {
      return this.infracoes.some((infracao) => {
        return infracao.isPending
      })
    },
    boletosEmAbertoLink () {
      const hash = this.$store.getters['sessions/hash']
      return `${process.env.VUE_APP_BASE_URL}/documento/${process.env.VUE_APP_CLIENTID}/boletosemaberto?hash=${hash}`
    }
  },
  mounted () {
    const hash = this.$store.getters['sessions/hash']

    this.getDados(hash).then(() => {
      this.loading = false
      this.defaultOpenedDetails.push(this.infracoes[0].idInfracao)
    })
  },
  methods: {
    ...mapActions('infracoes', [
      'getInfracoes'
    ]),
    async getDados (hash) {
      if (hash === '' || hash === null) {
        hash = localStorage.getItem('placa-renavam-hash')
        this.$store.commit('sessions/SESSION_SUCCESS', hash)
      }
      return await this.getInfracoes(hash).catch(() => {})
    },
    formatDate (date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm')
    },
    formatValue (value) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    },
    returnIndexPage () {
      this.$store.commit('infracoes/limpaInfracoes')
      localStorage.clear()
      this.$router.push('/')
    },
    toggle (row) {
      this.$refs.table.toggleDetails(row)
    }
  }
}
</script>

<style>
tr:nth-child(even) {
  background-color: #31313117;
}
</style>
