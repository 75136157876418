<template>
  <div class="is-size-6 has-text-dark" style="margin-left:20px; margin-bottom:10px">
    <div v-if="nomeIndicado != ''">
      <span class="has-text-weight-semibold">Interessado: </span>
      <span>{{ nomeIndicado }}</span>
    </div>
    <div v-if="dataIndicacao != ''">
      <span class="has-text-weight-semibold">Data de Cadastro: </span>
      <span>{{ dataIndicacao }}</span>
    </div>
    <div>
      <span class="has-text-weight-semibold">Status: </span>
      <span>{{ status }}</span>
    </div>
    <div v-if="motivo != ''">
      <span class="has-text-weight-semibold">Motivo: </span>
      <span>{{ motivo }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndicacaoDetail',
  props: {
    indicacao: {
      type: Object,
      required: true
    }
  },
  computed: {
    nomeIndicado () {
      if (this.indicacao.nomeIndicado != null) {
        return this.indicacao.nomeIndicado
      }
      return ''
    },
    status () {
      return this.indicacao.status
    },
    dataIndicacao () {
      if (this.indicacao.dataIndicacao != null) {
        return this.indicacao.dataIndicacao
      }
      return ''
    },
    protocolo () {
      if (this.indicacao.nrProtocolo != null) {
        return this.indicacao.nrProtocolo
      }
      return ''
    },
    motivo () {
      if (this.indicacao.motivoArquivamento != null) {
        return this.indicacao.motivoArquivamento.toUpperCase()
      }
      return ''
    }
  }
}
</script>
